import { useCallback } from 'react';

import CustomButton from '../../../common/CustomButton/CustomButton';
import CustomForm from '../../../common/CustomForm/CustomForm';
import CustomInput from '../../../common/CustomInput/CustomInput';
import Message from '../../../common/Message/Message';
import { PASSWORD_REGEX } from '../../../core/constants';
import { useUserContext } from '../../../core/context/user.context';
import { isEmpty, isValidEmail } from '../../../core/helpers';
import useFetch from '../../../core/hooks/useFetch';
import { useFormInput } from '../../../core/hooks/useFormInput';
import './ChangeEmailForm.css';

function ChangeEmailForm() {
  const { user } = useUserContext();
  const newEmail = useFormInput('', (value) => !isEmpty(value) && isValidEmail(value));
  const password = useFormInput('', (value) => !isEmpty(value) && PASSWORD_REGEX.test(value));
  const { doFetch, error, loading } = useFetch(
    `${process.env.REACT_APP_API_URL}/user/request-email-change`,
    true,
  );

  const handleSubmit = useCallback(async () => {
    const res = await doFetch({
      method: 'POST',
      headers: {
        Authorization: `Bearer ${user.token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: newEmail.value,
        password: password.value,
        source: 'PLATFORM',
      }),
    });

    if (res?.status === 'success') {
      newEmail.setValue('');
      password.setValue('');
    }
  }, [doFetch, user.token, newEmail, password]);

  return (
    <div className="new-email-form">
      <CustomForm inputs={[newEmail, password]} onSubmit={handleSubmit}>
        <div className="form__section">
          <h2 className="form__title h3">Change e-mail</h2>
          <CustomInput
            id="new-email"
            type="text"
            name="new-email"
            placeholder="Enter new e-mail address"
            label="New e-mail address"
            input={newEmail}
            emptyMessage="Please fill in a new e-mail address"
            invalidMessage="Please fill in a valid e-mail address"
            autoComplete="off"
          />
          <CustomInput
            id="new-email-password"
            type="password"
            name="password"
            placeholder="Your password"
            label="Your password"
            input={password}
            emptyMessage="Please fill in your password"
            invalidMessage="Please fill in a valid password, minimum eight characters, one uppercase letter, one lowercase letter, one digit and one special character."
            autoComplete="off"
          />
          <CustomButton
            className="new-email-form__cta btn btn--primary"
            type="submit"
            loading={loading}
          >
            Save changes
          </CustomButton>
        </div>
      </CustomForm>
      {!error ? null : <Message text={error.message} status="error" />}
    </div>
  );
}

export default ChangeEmailForm;
