/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useMemo, useState } from 'react';

const useIntersection = (ref: any, threshold: number) => {
  const [isIntersecting, setIsInterecting] = useState(false);
  const [wasIntersecting, setWasInterecting] = useState(false);

  const observer = useMemo(
    () =>
      new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              if (!wasIntersecting) setWasInterecting(true);
              setIsInterecting(true);
            } else {
              setIsInterecting(false);
            }
          });
        },
        {
          rootMargin: '0px',
          threshold,
        },
      ),
    [threshold, wasIntersecting],
  );

  useEffect(() => {
    const el = ref.current;
    if (!observer || !el) return;
    observer.observe(el);

    return () => {
      if (el) observer.unobserve(el);
    };
  }, [observer, ref]);

  const value = useMemo(
    () => ({ isIntersecting, wasIntersecting }),
    [isIntersecting, wasIntersecting],
  );

  return value;
};

export default useIntersection;
