import { memo, useCallback, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';

import { CustomInputInteface } from '../../core/models/inputs';
import { Icon } from '../Icon/Icon';

interface Props {
  id: string;
  link?: {
    text: string;
    href: string;
  };
  type: string;
  name: string;
  info?: string;
  label?: string;
  icon?: JSX.Element;
  placeholder: string;
  emptyMessage?: string;
  autoComplete?: string;
  invalidMessage?: string;
  className?: string;
  input: CustomInputInteface;
  disabled?: boolean;
  onFocus?: () => void;
  onBlur?: () => void;
  readOnly?: boolean;
}

function CustomInput({
  id,
  link,
  type,
  name,
  info,
  label,
  icon,
  placeholder,
  emptyMessage,
  invalidMessage,
  className,
  disabled,
  input,
  autoComplete = 'on',
  onFocus,
  onBlur,
  readOnly,
}: Props) {
  const [currentType, setCurrentType] = useState(type);
  const error = useMemo(
    () => (!input.showError ? '' : input.isEmpty ? 'empty' : !input.isValid ? 'invalid' : ''),
    [input.showError, input.isEmpty, input.isValid],
  );

  const handleFocus = useCallback(() => {
    if (onFocus) onFocus();
  }, [onFocus]);

  const handleBlur = useCallback(() => {
    if (onBlur) onBlur();
  }, [onBlur]);

  return (
    <div
      className={`input${!error ? '' : ' error'}${!icon ? '' : ' with-icon'}${
        !className ? '' : ` ${className}`
      }`}
    >
      {!label && !link ? null : (
        <div className="input__header">
          {!label ? null : (
            <label className="input__label body-label" htmlFor={id}>
              {label}
            </label>
          )}
          {!link ? null : (
            <Link className="input__link" to={link.href}>
              {link.text}
            </Link>
          )}
        </div>
      )}
      <div className="input__wrapper">
        <input
          id={id}
          autoComplete={autoComplete}
          type={currentType || 'text'}
          name={name}
          value={input.value}
          onChange={input.onChange}
          placeholder={placeholder}
          disabled={disabled}
          readOnly={readOnly}
          onFocus={handleFocus}
          onBlur={handleBlur}
          ref={input.ref}
          className={`input__field${type === 'password' ? ' input__field--password' : ''}`}
        />
        {type !== 'password' ? null : (
          <button
            className="input__btn"
            type="button"
            onClick={() => setCurrentType(currentType === 'password' ? 'text' : 'password')}
          >
            <span className="sr-only">Change input type</span>
            {currentType === 'password' ? (
              <Icon.Eye className="icon-large" />
            ) : (
              <Icon.EyeClosed className="icon-large" />
            )}
          </button>
        )}
        {!icon ? null : <span className="input__icon">{icon}</span>}
        {!info ? null : <div className="input__info caption">{info}</div>}
      </div>
      {!error ? null : (
        <div className="input__error">
          <span>
            {error === 'empty' ? emptyMessage : error === 'invalid' ? invalidMessage : ''}
          </span>
        </div>
      )}
    </div>
  );
}

const MemoizedCustomInput = memo(CustomInput);

export default MemoizedCustomInput;
