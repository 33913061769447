import React from 'react';
import ReactMarkdown from 'react-markdown';

import CustomLink from '../../common/CustomLink/CustomLink';
import Image from '../../common/Image/Image';
import { IImage, ILink } from '../../core/models';
import { IForm } from '../../core/models/form';
import HubSpotFromModal from '../HubSpotFromModal/HubSpotFromModal';
import './PageBannerSmall.css';

interface IProps {
  title: string;
  desc?: string;
  img?: IImage;
  cta?: ILink;
  form?: IForm;
  slug?: string;
}

function PageBannerSmall({ title, desc, img, cta, form, slug }: IProps) {
  return (
    <section className="page-banner-small">
      <div className="wrap">
        <div className="page-banner-small__container gap-lg">
          <div className="page-banner-small__content flex gap-md">
            <h1 className="page-banner-small__title h1">
              <ReactMarkdown
                components={{
                  p: React.Fragment,
                }}
              >
                {title}
              </ReactMarkdown>
            </h1>
            {!desc ? null : (
              <ReactMarkdown className="page-banner-small__desc h3">{desc}</ReactMarkdown>
            )}
            {!cta?.text && !form?.btnLabel ? null : (
              <div className="page-banner-small__cta-container gap-sm">
                {!cta ? null : <CustomLink link={cta} />}
                <HubSpotFromModal form={form} slug={slug} />
              </div>
            )}
          </div>
          {!img ? null : (
            <div className="page-banner-small__img-container">
              <Image className="cover-img" img={img} size="medium" />
            </div>
          )}
        </div>
      </div>
    </section>
  );
}

export default PageBannerSmall;
