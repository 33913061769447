/* eslint-disable no-console */
import { useEffect, useRef } from 'react';

import { useScreenSizeContext } from '../../core/context/screenSize.context';

interface IProps {
  publicToken: string;
  appId: string;
}

function EmbeddedAI({ publicToken, appId }: IProps) {
  const { isMobile } = useScreenSizeContext();
  const ref = useRef<HTMLIFrameElement>(null);

  useEffect(() => {
    if (!ref.current) return;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const initChat = ({ data }: { data: any }) => {
      if (data._MindStudioEvent === '@@mindstudio/LOADED') {
        const userId = window.localStorage.getItem(`@@mindstudio-sdk/userIds/${appId}`) || '';

        ref.current?.contentWindow?.postMessage(
          {
            _MindStudioEvent: '@@mindstudio/INITIALIZE',
            payload: {
              appId,
              publicToken,
              userIdOrNull: userId || null,
              options: null,
              parentUrl: document.URL,
            },
          },
          '*',
        );
      }
      if (data._MindStudioEvent === '@@mindstudio/USER_ID_UPDATED')
        try {
          window.localStorage.setItem(`@@mindstudio-sdk/userIds/${appId}`, data.payload.userId);
        } catch (err) {
          console.log(err);
        }
    };

    window.addEventListener('message', initChat);

    return () => {
      window.removeEventListener('message', initChat);
    };
  }, [appId, publicToken]);

  return (
    <iframe
      ref={ref}
      referrerPolicy="origin"
      style={{
        width: '100%',
        height: isMobile ? '60vh' : '580px',
        border: '0',
        borderRadius: '8px',
        outline: 'none',
      }}
      title="AI Embed"
      src="https://app.mindstudio.ai/_embeds/app?__displayContext=embedded&__controlledAuth=1"
    />
  );
}

export default EmbeddedAI;
