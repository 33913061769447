import React, { useCallback, useState } from 'react';
import ReactMarkdown from 'react-markdown';

import LinkRenderer from '../../common/LinkRenderer/LinkRenderer';
import './FAQ.css';
import FAQItem from './FAQItem/FAQItem';

interface IProps {
  title?: string;
  desc?: string;
  side?: 'left' | 'right';
  items: {
    question: string;
    answer: string;
  }[];
}

function FAQ({ title, desc, side, items }: IProps) {
  const [openIdx, setOpenIdx] = useState<number | null>(null);

  const handleToggle = useCallback((i: number) => {
    setOpenIdx((prevState) => (prevState === i ? null : i));
  }, []);

  return (
    <section className={`faq section${side === 'left' ? ' faq--left' : ''}`}>
      <div className="wrap">
        <div className="faq__container">
          {!title && !desc ? null : (
            <div className="faq__header">
              {!title ? null : (
                <h2 className="faq__title h3">
                  <ReactMarkdown
                    components={{
                      p: React.Fragment,
                    }}
                  >
                    {title}
                  </ReactMarkdown>
                </h2>
              )}
              {!desc ? null : (
                <ReactMarkdown
                  className="faq__desc"
                  components={{
                    a: LinkRenderer,
                  }}
                >
                  {desc}
                </ReactMarkdown>
              )}
            </div>
          )}
          <div className="faq__questions">
            {items.map((item, i) => (
              <FAQItem
                item={item}
                isOpen={openIdx === i}
                i={i}
                handleToggle={handleToggle}
                key={item.question}
              />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}

export default FAQ;
