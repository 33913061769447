interface IProps {
  styles?: React.CSSProperties;
}

function Loader({ styles }: IProps) {
  return (
    <div className="page-loader fade-in" style={styles}>
      <span className="sr-only">Loading</span>
      <div className="loader" />
    </div>
  );
}

export default Loader;
