import React, { useCallback, useRef } from 'react';
import ReactMarkdown from 'react-markdown';

import CustomButton from '../../common/CustomButton/CustomButton';
import CustomLink from '../../common/CustomLink/CustomLink';
import { Icon } from '../../common/Icon/Icon';
import { slugify } from '../../core/helpers';
import useModal from '../../core/hooks/useModal';
import { ILink } from '../../core/models';
import Chat from './Chat/Chat';
import './GPT2.css';

interface IProps {
  slug: string;
  title: string;
  desc?: string;
  cta?: ILink;
  gpt?: {
    title?: string;
    assistantID?: string;
    btnLabel?: string;
    btnId?: string;
  };
  background?: 'red' | 'white' | 'dark-blue' | 'lite-blue';
}

function GPT2({ slug, title, desc, cta, gpt, background }: IProps) {
  const { modal, open, close } = useModal();
  const btnRef = useRef(null);

  const openModal = useCallback(() => {
    if (!btnRef.current) return;
    open(btnRef.current);
  }, [open]);

  return (
    <section
      className={`gpt section-bg${
        !background || background === 'red'
          ? ' section-bg--secondary'
          : background === 'white'
          ? ' section-bg--white'
          : background === 'dark-blue'
          ? ' section-bg--dark'
          : background === 'lite-blue'
          ? ' section-bg--primary'
          : ''
      }`}
    >
      <div className="wrap">
        <div className="gpt__container gap-lg">
          <div className="gpt__left">
            <h2 className="gpt__title h2">
              <ReactMarkdown
                components={{
                  p: React.Fragment,
                }}
              >
                {title}
              </ReactMarkdown>
            </h2>
          </div>
          <div className="gpt__right gap-md">
            {!desc ? null : <ReactMarkdown className="gpt__desc h3">{desc}</ReactMarkdown>}
            {!cta && !gpt?.assistantID ? null : (
              <div className="gpt__btn-container">
                {!cta ? null : <CustomLink className="gpt__cta" link={cta} />}
                {!gpt?.assistantID ? null : (
                  <CustomButton
                    className={`btn btn--${background === 'lite-blue' ? 'secondary' : 'primary'}`}
                    onClick={openModal}
                    type="button"
                    ref={btnRef}
                    id={gpt?.btnId}
                  >
                    {gpt.btnLabel || 'Open'}
                  </CustomButton>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      {!gpt?.assistantID
        ? null
        : modal({
            keepMounted: true,
            children: (
              <>
                <CustomButton
                  className="modal__close"
                  onClick={close}
                  type="button"
                  id={`${slugify(title)}-modal-close`}
                >
                  <Icon.Plus className="icon-large" />
                  <span className="sr-only">Close modal</span>
                </CustomButton>
                {!gpt?.assistantID ? null : (
                  <Chat assistantID={gpt.assistantID} title={gpt.title} />
                )}
              </>
            ),
          })}
    </section>
  );
}

export default GPT2;
