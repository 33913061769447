import React, { useEffect } from 'react';
import ReactMarkdown from 'react-markdown';

import video from '../../assets/banner.mp4';
import logo from '../../assets/logo-full.svg';
import Image from '../../common/Image/Image';
import { useScreenSizeContext } from '../../core/context/screenSize.context';
import { useThemeContext } from '../../core/context/theme.context';
import { IImage } from '../../core/models';
import './HomeBanner.css';

interface IProps {
  title: string;
  img?: IImage;
  showImage?: boolean;
}

function HomeBanner({ title, img, showImage }: IProps) {
  const { isTablet, isMobile } = useScreenSizeContext();
  const { setHideLogo } = useThemeContext();

  useEffect(() => {
    setHideLogo(true);

    return () => {
      setHideLogo(false);
    };
  }, [setHideLogo]);

  return (
    <section className="home-banner">
      <div className="wrap">
        <div className="home-banner__container">
          <div className="home-banner__content">
            <img className="home-banner__logo" src={logo} alt="THNK" />
            <h1 className={`home-banner__title${isMobile || isTablet ? ' h3' : ' h2'}`}>
              <ReactMarkdown
                components={{
                  p: React.Fragment,
                }}
              >
                {title}
              </ReactMarkdown>
            </h1>
          </div>
          {!showImage ? (
            <div className="home-banner__media-container">
              <video
                className="home-banner__media"
                src={video}
                autoPlay
                muted
                aria-hidden
                playsInline
              />
            </div>
          ) : img ? (
            <div className="home-banner__media-container">
              <Image
                className="home-banner__media"
                img={img}
                size={isMobile ? 'small' : 'medium'}
              />
            </div>
          ) : null}
        </div>
      </div>
    </section>
  );
}

export default HomeBanner;
